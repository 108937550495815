import React from 'react'
import styled from 'styled-components'

import { useQueryParams } from '@procsea/common/hooks'
import { GlobalStyle, Stack, Text } from '@procsea/design-system'

import useDocumentTitle from 'src/hooks/useDocumentTitle'
import klarysLogo from 'src/images/klarys-logo.svg'

import { AuthContainer, AuthMainCard, HeaderLogo } from '../common'
import Form from './Form'

const DEFAULT_BACKGROUND_COLOR = '#ffffff'

interface BackgroundProps {
  backgroundColor: string
}

const Background = styled.div<BackgroundProps>`
  background-color: ${props => props.backgroundColor};
  min-height: 100vh;
  padding: ${props => props.theme.spacings.large};
  text-align: center;
  width: 100vw;
`

const BuyerSignup = () => {
  const queryParams = useQueryParams()

  useDocumentTitle(gettext('Customer Sign Up'))

  const isEmbedded = queryParams.get('embedded') === 'true'
  const backgroungColor = queryParams.get('bg_color') || DEFAULT_BACKGROUND_COLOR
  const ctaColor = queryParams.get('cta_color')
  const companyUuid = queryParams.get('to')

  if (isEmbedded) {
    return (
      <Background backgroundColor={backgroungColor}>
        <Form companyUuid={companyUuid} customCtaColor={ctaColor} showSignInLink={false} />
      </Background>
    )
  }

  return (
    <AuthContainer>
      <GlobalStyle styledTheme="light" />
      <a data-e2e="buyer-signup-home-link" href={window.WEBSITE_URL || '/'}>
        <HeaderLogo alt="Klarys logo" src={klarysLogo} />
      </a>
      <Stack spacing="xsmall">
        <Text size="xxxlarge" variant="secondary">
          {gettext('Create a customer account')}
        </Text>
        <Text size="large" variant="secondary">
          {gettext('Search, compare, buy your fresh food products')}
        </Text>
      </Stack>
      <AuthMainCard shadow="middle" spacing="xlarge">
        <Form companyUuid={companyUuid} showSignInLink />
      </AuthMainCard>
    </AuthContainer>
  )
}

export default BuyerSignup
